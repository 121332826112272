.container .field {
  margin-block-end: var(--spacing-16);
  width: 50%;
}

.actions {
  margin-block-start: var(--spacing-16);
}

.actions button {
  margin-inline-end: var(--spacing-16);
}

h3 {
  font-size: 17px;
  font-weight: 500;
  color: #7e8186;
  margin-block-end: var(--spacing-16);
}

.label {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bolder);
}
