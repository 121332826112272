:root {
  --top-nav-height: 4rem;
  --side-nav-width: 14rem;
  --page-header-height: 16rem;
  --z-fixed: 900;

  --color-plan-premier: var(--color-viz-14);
  --color-plan-essential: var(--color-viz-04);
  --color-plan-negative: var(--color-viz-negative);
  --color-plan-business: var(--color-viz-02);
  --color-plan-free: var(--color-viz-positive-fill);
  --color-plan-question-set: var(--color-viz-neutral-fill);
  --color-badge-include: var(--color-viz-13);
}
