.badge {
  align-items: center;
  border-radius: 2px;
  display: flex;
  justify-content: center;
}

.badge.anonymized {
  background-color: var(--color-bg-primary);
}

.badge.anonymized .text {
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
}

.badge.deleted {
  background-color: var(--color-status-negative);
}

.text {
  font-size: var(--font-size-2xs);
  font-weight: bold;
  color: var(--color-text-inverted);
  padding: 1px 5px;
  text-transform: uppercase;
}
