.column__small {
  width: 90px;
}
.container {
  color: var(--color-text-tertiary);
}

.content {
  margin-inline-start: var(--side-nav-width);
  margin-block-start: var(--top-nav-height);
  flex: 1;
  padding: 2rem;
}

.table table {
  width: 100%;
  color: var(--color-text-primary);
  table-layout: fixed;
}

.table table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.table table tr:hover {
  background-color: var(--color-table-row--hover);
}

.table table tr:hover strong {
  text-decoration: underline;
}

.table table thead tr {
  color: var(--color-text-secondary);
}

.table table thead tr:hover {
  cursor: default;
  background: transparent;
}

.table table tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table table tr > td:last-of-type {
  overflow: visible;
}

.table table tr td button:first-child {
  position: relative;
  top: 1px;
}

.table table tr td button {
  margin-inline-start: 8px;
}

.action {
  flex: 1;
}

.content form {
  align-items: center;
  display: flex;
  padding: 20px 20px 50px 0;
}

.content form > div {
  padding: 0 10px 0 0;
}

.field,
.submit {
  width: 250px;
  margin-block-end: var(--spacing-08);
}

.submit {
  position: relative;
  bottom: -11px;
}

.select {
  padding-right: 10px;
}

.name {
  color: var(--color-status-highlight);
  cursor: pointer;
}

.name:hover {
  text-decoration: underline;
}

.formComponent {
  flex-wrap: wrap;
}
