.container {
  background: var(--color-bg-info);
  box-shadow:
    inset 0 1px 0 0 rgba(0, 0, 0, 0),
    inset 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  min-height: 205px;
  padding: 20px 0 0;
}

.content {
  flex: 1;
  padding: 0 2rem;
  margin-inline-start: var(--side-nav-width);
  margin-block-start: var(--top-nav-height);
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bolder);
}

.blocked {
  background-color: #561d00;
}
