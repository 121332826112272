.container {
  margin-bottom: var(--spacing-64);
}

.productPlans {
  margin: 20px 30px;
  display: flex;
}

.productPlans > div {
  max-width: 100px;
  margin: 0 20px 0 0;
}

.plans {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.plan {
  margin: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--color-bg-card);
  border: solid 1px var(--color-border);
}

.stacked,
.plan {
  width: calc(100% / 4);
  height: 100%;
}

.plan.beta {
  width: 100%;
  height: 20%;
}

.stacked {
  margin: 0 20px 10px 20px;
}

.plan.questionSet {
  width: 100%;
  height: 80%;
}

.plan h3 {
  font-size: 24px;
  font-weight: bold;
  color: #4a4a4a;
  text-transform: capitalize;
  padding: 20px 30px 0 30px;
}

.plan ul {
  list-style-type: none;
  padding: 15px 0;
  margin: 0;
}

.plan ul li label {
  white-space: initial;
}

.addOns {
  padding: 0 30px 10px 30px;
}

.radio :global(.peak-form-radio) {
  flex-basis: auto !important;
}

.radio :global(.peak-form-radio .peak-radio-label) {
  display: block;
}

.subContractors {
  border-top: solid 1px #dedede;
  background-color: #fffefb;
  padding: 32px 30px;
}

.subContractorText {
  font-size: 12px;
  font-weight: 600;
  color: #4a4a4a;
}

.notice {
  padding: 10px 0 0 0;
  color: #c4851c;
}

.bar {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background: #fff;
  box-shadow:
    0 -3px 0 0 rgba(0, 0, 0, 0.02),
    inset 0 1px 0 0 #e6e8eb;
  transition: height 0.3s ease;
  visibility: hidden;
}

.barVisible {
  height: 74px;
  visibility: visible;
  z-index: 100;
}

.barContent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;
  height: 100%;
}

.barContent span {
  padding: 0 20px;
}

.cancel {
  text-decoration: underline;
  cursor: pointer;
}

.locked::before {
  border: 1px dashed rgb(231, 232, 231);
  border-radius: 2px;
}

.locked {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-sm);
  height: 40px;
  justify-content: flex-start;
  position: relative;
}

.iconContainer {
  align-items: center;
  border-radius: 2px;
  border: 1px dashed rgb(231, 232, 231);
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 0.75rem;
  width: 16px;
}

.lockedIcon {
  margin-left: 1px;
}

.radioButton {
  margin-right: 3px;
}

.confirm {
  display: flex;
  margin-top: 20px;
}

.confirm label {
  margin-bottom: 0;
}

.footer button {
  margin-left: 20px;
}

.spinner {
  color: var(--color-status-highlight);
  display: flex;
  flex-direction: column;
  align-items: center;
}
