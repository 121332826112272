.container {
  display: flex;
  min-height: calc(100vh - var(--top-nav-height)); /* fallback */
  min-height: calc(100dvh - var(--top-nav-height));
}

.spinnerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-status-highlight);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1;
}
