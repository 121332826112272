.container h2 {
  font-size: 21px;
  font-weight: 500;
  color: var(--color-text-tertiary);
  margin: 20px 0;
}

.container table {
  width: 100%;
  margin: 20px 0;
}

.container table tr {
  height: 53px;
  border-bottom: 1px solid var(--color-border);
}

.container table thead tr {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
  cursor: default;
}

.container table td {
  padding: 5px;
}

.container strong,
.modalTitle strong {
  font-weight: 600;
}

.container ul {
  padding: 0;
}

.container li {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}

.modalTable th {
  width: 16.66667%;
  font-weight: 600;
  padding: 6px 13px;
  border: 1px solid var(--color-border);
}

.modalTable td {
  width: 83.33333%;
  padding: 6px 13px;
  border: 1px solid var(--color-border);
}

.modalTable tr:nth-child(2n) {
  background-color: var(--color-bg-primary);
}

.modalTable svg {
  margin-top: -2px;
  margin-right: 3px;
}

.text {
  text-transform: capitalize;
}

.visibilitySensor {
  color: var(--color-text-inverted);
}

.filtering {
  display: flex;
  flex-wrap: wrap;
}

.filter {
  width: 250px;
  margin-block-end: var(--spacing-08);
}

.export {
  padding-top: 25px;
}

.filter:not(:last-of-type) {
  margin-right: 10px;
}

.filter label {
  color: #52676d;
  display: inline-block;
  font-size: 11px;
  margin-bottom: 8px;
  max-width: 100%;
  text-align: left;
}

.empty {
  margin: 0 auto;
  padding-top: 50px;
}

.reset {
  display: block;
  padding-top: 12px;
}

.notSet,
.system {
  font-style: italic;
}

.peakonLogo {
  width: 64px;
  display: flex;
}

.actions {
  padding-top: 10px;
}
