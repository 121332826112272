.topNav {
  position: fixed;
  top: 0;
  background-color: var(--color-bg-card);
  border-block-end: 1px solid var(--color-border);
  padding-inline: var(--spacing-08);
  height: var(--top-nav-height);
  z-index: calc(var(--z-fixed) + 1);
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.link {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: var(--spacing-16);
  color: var(--color-text-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  margin-inline-start: var(--spacing-08);
}

.link .logo {
  height: 2rem;
}

.link:focus,
.link:hover {
  text-decoration: none;
}
