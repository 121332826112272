.input {
  line-height: normal;
}

/* hides the native calendar icon and stretches it over the whole field so you can click anywhere to trigger the native datepicker */
.input::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
