.badge {
  align-items: center;
  border-radius: 2px;
  display: flex;
  justify-content: center;
}

.badge.free {
  background-color: var(--color-plan-free);
}

.badge.essential,
.badge.paid,
.badge.general {
  background-color: var(--color-plan-essential);
}

.badge.business {
  background-color: var(--color-plan-business);
}

.badge.premier {
  background-color: var(--color-plan-premier);
}

.badge.include {
  background-color: var(--color-badge-include);
}

.badge.questionSet {
  background-color: var(--color-plan-question-set);
  color: var(--color-text);
}

.badge.churned,
.badge.blocked,
.badge.betaFeatures {
  background-color: var(--color-plan-negative);
}

.badge.free .text,
.badge.essential .text,
.badge.paid .text,
.badge.general .text,
.badge.premier .text,
.badge.churned .text,
.badge.blocked .text,
.badge.betaFeatures .text {
  color: var(--color-text-inverted);
}

.badge.full {
  padding: 10px;
}

.badge.label {
  display: inline-block;
}

.text {
  font-size: var(--font-size-2xs);
  font-weight: bold;
  color: var(--color-text-primary);
  padding: 1px 5px;
  text-transform: uppercase;
}

.badge.label.essential {
  background-color: var(--color-plan-essential);
}

.badge.label.business {
  background-color: var(--color-plan-business);
}

.badge.label.premier {
  background-color: var(--color-plan-premier);
}

.badge.label.essential .text {
  color: var(--color-text-inverted);
}

.badge.label.business .text {
  color: var(--color-text-inverted);
}

.badge.label.premier .text {
  color: var(--color-text-inverted);
}
