.container {
  padding: 20px 0;
}

.unsubscribed {
  color: var(--color-status-negative);
}

.actions button {
  width: 100px;
}
