.content {
  margin-inline-start: var(--side-nav-width);
  flex: 1;
  padding: 2rem;
}

.content table {
  width: 100%;
  color: var(--color-text-primary);
  table-layout: fixed;
}

.content table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.content table tr:hover {
  background-color: var(--color-table-row--hover);
}

.content table tr:hover strong {
  text-decoration: underline;
}

.content table thead tr {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.content table thead tr:hover {
  cursor: default;
  background: transparent;
}

.content table tbody tr:first-child {
  background-color: var(--color-bg-primary);
}

.content table tbody tr td:first-child {
  width: 100px;
  padding: 0 20px;
}

.content table tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content table tbody tr td {
  padding-right: 20px;
}

.content table tr td button {
  position: relative;
}

.column__medium {
  width: 120px;
}

.column__narrow {
  width: 90px;
}

.spinnerCreating {
  justify-content: left;
}

.badge {
  align-items: center;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  display: inline-block;
  background-color: var(--color-plan-free);
}

.badge .text {
  font-size: var(--font-size-2xs);
  font-weight: bold;
  color: var(--color-text-primary);
  padding: 1px 5px;
  text-transform: uppercase;
}

.header {
  display: flex;
  flex: 1;
  gap: var(--spacing-16);
}

.headerCard {
  padding: var(--spacing-16);
  margin-block-end: var(--spacing-16);
  flex: 1;
}

.headerCard form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
