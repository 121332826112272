.container {
  margin-inline-start: var(--side-nav-width);
  margin-block-start: var(--top-nav-height);
  flex: 1;
  padding: 2rem;
}

.container table {
  width: 100%;
  color: var(--color-text-primary);
}

.container table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.container table tr:hover {
  background-color: var(--color-table-row--hover);
}

.container table tr:hover strong {
  text-decoration: underline;
}

.container table thead tr {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.container table thead tr:hover {
  cursor: default;
  background: transparent;
}

.container table tbody tr td:first-child {
  width: 200px;
}

.container table tr td button:first-child {
  position: relative;
  top: 1px;
}

.container table tr td button {
  margin: 0 0 0 8px;
}

.pendingContainer {
  margin: 50px 0;
}

.isDeletionDue {
  color: var(--color-status-negative);
}
