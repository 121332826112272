.block {
  margin: 0 0 20px 0;
}

.danger {
  color: var(--color-status-negative);
}

.cardBody {
  min-height: 65px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.cardBodyItem {
  margin: 0 50px 0 0;
  min-height: 65px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardBodyItemTitle {
  font-size: var(--font-size-xs);
  font-weight: 600;
  margin-bottom: 3px;
  color: #3a3e47;
}

.cardBodyItemContent {
  font-size: 21px;
  color: #3a3e47;
}

.cardBodyItemDescription {
  font-size: 13px;
  font-weight: 600;
  color: #c8cbcf;
  min-height: 18px;
}

.csm .cardBodyItemContent {
  font-size: var(--font-size-sm);
  white-space: pre;
}

.deletionTime {
  font-weight: var(--font-weight-bold);
}
