.container {
  color: var(--color-text-tertiary);
}

.content {
  margin-inline-start: var(--side-nav-width);
  margin-block-start: var(--top-nav-height);
  max-width: 600px;
  padding: 2rem;
}

.field:not(:first-of-type),
.submit {
  margin-block-start: 2rem;
}

.warning {
  padding: 10px 0 0 0;
  color: #ff0000;
}

.warning > strong {
  font-weight: bold;
  text-transform: uppercase;
}
