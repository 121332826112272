.sso {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.loading {
  display: flex;
  justify-content: center;
  height: 100%;
}

.logo {
  margin: 0 auto;
  height: 120px;
  padding: 30px 0;
  display: block;
}

.form {
  max-width: 400px;
  margin: 0 auto;
}

.input {
  padding: 10px 0;
}

.actions {
  text-align: right;
}

.actions button {
  margin: 0 0 0 10px;
}

.container {
  margin-block-start: var(--top-nav-height);
}

.hr-text {
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  opacity: 0.5;
  margin: 20px 0;
  &:before {
    content: '';
    background: var(--color-text);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: var(--color-text);
    padding: 0 10px;
    background-color: #fff;
  }
}
