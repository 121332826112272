.container table {
  width: 100%;
  margin: 20px 0;
}

.container table tr {
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.button button {
  margin: rem(8px 0);
}

.spinner {
  color: var(--color-status-highlight);
  display: flex;
  flex-direction: column;
  align-items: center;
}
