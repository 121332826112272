.picker {
  overflow: hidden;
  position: relative;
}

.input {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.button {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
