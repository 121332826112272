.container button {
  display: inline-block;
  margin: 0 5px 0 0;
}

.action {
  padding: 20px 0;
  margin: 20px 0;
  border-bottom: solid 1px #dedede;
}

.action h3 {
  font-size: 17px;
  font-weight: 500;
  color: #7e8186;
  margin: 0;
}

.action ul {
  padding: 20px;
}
