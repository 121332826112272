.column__small {
  width: 90px;
}

.column__xsmall {
  width: 60px;
}

.column__actions {
  text-align: center;
}

.action,
.filterText,
.filter {
  flex: 1;
}

.action {
  min-width: 25rem;
}

.filter,
.filters,
.filterText {
  display: flex;
}

.filterText {
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 10px 10px 0;
}

.filter {
  flex-direction: column;
}

.separator {
  border-top: 1px solid var(--color-border);
}

.container {
  color: var(--color-text-tertiary);
}

.content {
  margin-inline-start: var(--side-nav-width);
  padding: 2rem;
  overflow-x: auto;
}

.table {
  width: 100%;
}

.table table {
  width: 100%;
  color: var(--color-text-primary);
  table-layout: fixed;
}

.table table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.table table tr:hover {
  background-color: var(--color-table-row--hover);
}

.table table tr:hover strong {
  text-decoration: underline;
}

.table table thead tr {
  color: var(--color-text-secondary);
}

.table table thead tr:hover {
  cursor: default;
  background: transparent;
}

.table table tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table table tr > td:last-of-type {
  overflow: visible;
}

.table table tr td button:first-child {
  position: relative;
  top: 1px;
}

.table table tr td button {
  margin-inline-start: 8px;
}

.action {
  flex: 1;
}

.content form {
  align-items: center;
  display: flex;
  padding: 20px 20px 50px 0;
}

.content form > div {
  padding: 0 10px 0 0;
}

.field,
.submit {
  width: 250px;
}

.submit {
  position: relative;
  bottom: -11px;
}

.select {
  padding-right: 10px;
}

.name {
  color: var(--color-status-highlight);
  cursor: pointer;
}

.name:hover {
  text-decoration: underline;
}

.btnCancel {
  padding-block-start: 1rem;
}
