.cardContainer {
  border-radius: 3px;
  border: solid 1px var(--color-border);
  width: 90%;
  margin: 20px 0;
  transition: box-shadow 0.25s;
}

.cardContainer:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.15);
}

.cardHeaderContainer {
  border-bottom: solid 1px var(--color-border);
  display: flex;
  align-items: center;
  min-height: 55px;
  text-decoration: none;
}

.cardHeaderContainer:hover {
  text-decoration: none;
}

.arrowContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.arrow {
  color: var(--color-text-tertiary);
}

.cardBody {
  min-height: 65px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cardBodyItem {
  margin: 0 10px;
  min-height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardBodyItemTitle {
  font-size: 9px;
  font-weight: 600;
  margin-bottom: 3px;
}

.cardBodyItemContent {
  font-size: 12px;
  margin-top: 3px;
}

.loginButton {
  margin: 0 10px;
}

.cardFooterContainer {
  border-top: solid 1px var(--color-border);
  display: flex;
  align-items: center;
  min-height: 48px;
}
