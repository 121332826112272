.container {
  margin-inline-start: var(--side-nav-width);
  margin-block-start: var(--top-nav-height);
  flex: 1;
  padding: 0 2rem 2rem;
}

.container table {
  width: 100%;
  table-layout: fixed;
  color: var(--color-text-primary);
}

.container table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.container table tr:hover {
  background-color: var(--color-table-row--hover);
}

.container table tr:hover strong {
  text-decoration: underline;
}

.container table thead tr {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.container table thead tr:hover {
  cursor: default;
  background: transparent;
}

.container table tr td:not(:first-child) {
  padding-inline: 0.7rem;
}

.companyId {
  text-align: center;
  width: 7rem;
}

.email {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grantAccess form {
  align-items: center;
  display: flex;
  padding: 20px 20px 50px 0;
}

.grantAccess form > div {
  padding: 0 10px 0 0;
}

.field,
.submit {
  width: 250px;
}

.submit {
  position: relative;
  bottom: -11px;
}
