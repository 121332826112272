.content {
  display: flex;
  flex-direction: column;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.item {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px 20px 20px 0;
  word-break: break-all;
}

.toggle {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.toggleLabel {
  margin-bottom: 0;
  margin-left: 10px;
}

.title {
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-text-tertiary);
}
