.sideNav {
  background-color: var(--color-bg-card);
  border-inline-end: 1px solid var(--color-border);
  width: var(--side-nav-width);
  overflow-y: auto; /* allow scrolling if it overflows */
  padding-block-end: var(--spacing-64);

  position: fixed;
  flex-shrink: 0;
  top: var(--top-nav-height);
  height: 100%;
  z-index: var(--z-fixed);
}

.sideNavLink {
  display: flex;
  color: var(--color-text-tertiary);
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  padding-inline: 1.25rem;
  padding-block: 0.75rem;
  font-size: var(--font-size-sm);
  align-items: center;
  gap: 0.5rem;
}

.sideNavLink:hover,
.sideNavLink:focus-visible,
.sideNavLink:active {
  color: var(--color-text-secondary);
  text-decoration: none;
}

.sideNavLink:hover {
  background-color: var(--color-controls--selected-bg);
}

.sideNavLink:focus {
  /* custom focus ring from side nav in dashboard */
  outline: none;
  box-shadow: none !important;
}

.sideNavLink:focus-visible {
  /* custom focus ring from side nav in dashboard */
  box-shadow: inset 0 0 0 0.125rem var(--color-focus-outer) !important;
}

.sideNavLink.isActive,
.sideNavLink[aria-current] {
  color: var(--color-text-primary);
  text-decoration: none;
  background-color: var(--color-controls--selected-bg);
  position: relative;
}

.sideNavLink.isActive::before,
.sideNavLink[aria-current]::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0.25rem;
  height: 100%;
  inset-inline-start: 0;
  background-color: var(--color-controls--selected-marker);
}
