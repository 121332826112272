.container table {
  width: 100%;
  color: var(--color-text-primary);
}

.container table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.container table tr:hover {
  background-color: var(--color-table-row--hover);
}

.container table tr:hover strong {
  text-decoration: underline;
}

.container table thead tr {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.container table thead tr:hover {
  cursor: default;
  background: transparent;
}

.container table td {
  padding: var(--spacing-04);
}

.container table .queued {
  color: var(--color-status-negative);
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-04);
}

.actions button {
  margin: 0 !important; /* the buttons inherits some margin which we don't want to have from the Employee/styles.css */
}

.spinner {
  display: flex;
  justify-content: center;
  border: none;
}
