.container {
  flex-grow: 1;
  margin: 0 auto;
  padding: 2rem 0;
}

.field {
  margin-bottom: 20px;
}

.actions {
  display: flex;
}

.actions button {
  margin-inline-end: 20px;
}

.table table {
  width: 100%;
  color: var(--color-text-primary);
}

.table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.table tr:hover {
  background-color: var(--color-table-row--hover);
  cursor: pointer;
}

.table tr:hover strong {
  text-decoration: underline;
}

.table tr.tableHeader {
  cursor: default;
}

.table tr.tableHeader:hover {
  background: transparent;
  font-weight: var(--font-weight-bold);
}

.table a:hover {
  text-decoration: none;
}

.content {
  display: flex;
  margin: 0 auto;
  padding: 2rem;
}
