.container h2 {
  font-size: 21px;
  font-weight: 500;
  color: var(--color-text-tertiary);
  margin: 0 0 20px 0;
}

.headerContent,
.companyHeader {
  display: flex;
}

.companyHeader {
  flex: 1;
  height: 126px;
}

.companyLogo {
  margin-right: 20px;
}

.companyLogo > div {
  border: 1px solid var(--color-border-section);
}

.companyPlan {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 10px;
}

.companyBlocked {
  color: var(--color-status-negative);
  padding: 0 10px;
}

.companyActions {
  display: inline-flex;
  flex-direction: column;
  margin-left: auto;
}

.companyActions > div {
  margin-bottom: 5px;
}

.companyActions button {
  margin: 0 10px 10px 0;
}

.bodyContent {
  margin-inline-start: var(--side-nav-width);
  padding: 2rem;
}

.footer {
  position: relative;
  top: 1px;
  /* make sure the tabs dont overflow the screen width */
  width: calc(100vw - var(--side-nav-width) - 6rem);
  overflow-x: auto;
}

.footer ul a:focus {
  border-color: var(--color-focus-inner);
}

.dueBlockDelete {
  color: var(--color-status-negative);
  margin-top: 10px;
}

.spinner {
  color: var(--color-status-highlight);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: var(--spacing-24);
}
