.container .field {
  margin-block-end: var(--spacing-08);
}

textarea {
  max-width: 800px;
}

.label {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bolder);
}

.title {
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: var(--color-text-tertiary);
}

.response {
  background: var(--color-bg-info);
  padding: 8px;
}
