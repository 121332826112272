.container table {
  width: 100%;
  margin: 20px 0;
}

.container table tr {
  height: 50px;
  border-bottom: 1px solid #e6e8eb;
}

.container table tr:first-child {
  border-top: 1px solid #e6e8eb;
}

.badge {
  align-items: center;
  margin-left: 20px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  display: inline-block;
  background-color: var(--color-plan-free);
}

.badge .text {
  font-size: var(--font-size-2xs);
  font-weight: bold;
  color: var(--color-text-primary);
  padding: 1px 5px;
  text-transform: uppercase;
}
