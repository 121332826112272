.container table {
  width: 100%;
  margin: 20px 0;
}

.container table tr {
  height: 50px;
  border-bottom: 1px solid #e6e8eb;
}

.container table tr:first-child {
  border-top: 1px solid #e6e8eb;
}

.container table tr td {
  padding-right: 20px;
}

.container table tr td:first-child {
  width: 120px;
}

.filter {
  width: 300px;
  margin-left: 8px;
}

.filter label {
  color: #52676d;
  display: inline-block;
  font-size: var(--font-size-xs);
  margin-bottom: 8px;
  max-width: 100%;
  text-align: left;
}

.button {
  width: 100px;
  margin-left: 20px;
}

.unpublishedVersion {
  background-color: var(--color-bg-primary);
}

.badge {
  align-items: center;
  margin-left: 20px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  display: inline-block;
  background-color: var(--color-plan-free);
}

.badge .text {
  font-size: var(--font-size-2xs);
  font-weight: bold;
  color: var(--color-text-primary);
  padding: 1px 5px;
  text-transform: uppercase;
}
