.container table {
  width: 100%;
  margin: 20px 0;
}

.spinner {
  color: var(--color-status-highlight);
  display: flex;
  flex-direction: column;
  align-items: center;
}
