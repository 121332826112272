.container {
  display: flex;
  flex-direction: column;
}

.company {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--color-border);
  border-bottom: none;
  padding: 10px;
}

.company:last-child {
  border-bottom: 1px solid var(--color-border);
}

.company:hover,
.company:focus {
  cursor: pointer;
  background-color: var(--color-table-row--hover);
  text-decoration: none;
}

.arrow {
  color: var(--color-text-tertiary);
}
