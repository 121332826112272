.container {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.container > div {
  margin: 0 7.5px;
}

.container > div:last-child {
  margin-right: 0;
}

.name {
  font-size: var(--font-size-large);
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  max-width: 300px;
}

.name:hover {
  text-decoration: underline;
}

.small .container {
  padding: 0;
}

.small .name {
  font-size: var(--font-size-base);
}
