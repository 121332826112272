.banner {
  display: flex;
  height: 50px;
  background: var(--color-bg-info);
  position: relative;
  margin-inline-start: var(--side-nav-width);
  margin-block-start: var(--top-nav-height);
}

.confetti {
  width: 100%;
  height: 50px;
}

.confetti,
.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.container {
  z-index: 1;
}

.content {
  display: flex;
  flex: 1;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  font-size: 20px;
  padding-inline: 2rem;
}

.image {
  margin-right: 34px;
}

.text {
  cursor: default;
}

.green {
  color: var(--color-status-highlight);
}
