.pagination {
  margin: 20px 0 50px;
  height: 62px;
}

.pagination ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin: 0 5px 0;
  cursor: pointer;
}

.pagination li a {
  background-color: rgb(242, 242, 242);
  padding: 8px;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.13);
  color: rgb(107, 109, 115);
  border: solid 1px rgba(0, 0, 0, 0.03);
  font-size: 13px;
  text-align: center;
}

li.disabled a {
  cursor: default;
  color: var(--color-text-disabled);
  text-decoration: none;
}

.selected a {
  text-decoration: underline;
}
