.container table {
  width: 100%;
  margin: 20px 0;
}

.container table tr {
  height: 50px;
  border-bottom: 1px solid #e6e8eb;
}

.container table tr:first-child {
  border-top: 1px solid #e6e8eb;
}

.allowAllDomains {
  padding: 0 0 20px 0;
}

.block {
  margin: 0 0 20px 0;
}
