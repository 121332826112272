.container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
}

.link {
  color: color(var(--color-text-inverted) alpha(0.5));
}

.link:focus,
.link:hover,
.link:active {
  color: var(--color-text-inverted);
}

.arrow {
  color: color(var(--color-text-inverted) alpha(0.5));
}
