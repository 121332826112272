.container form input {
  max-width: 400px;
}

.container form > div {
  padding: 10px 0;
}

.container form .saveButton {
  margin: 20px 0;
}

.service {
  display: flex;
}

.service > div {
  max-width: 400px;
  margin: 0 20px 0 0;
}

.label {
  color: var(--color-text-secondary);
  display: block;
  font-size: var(--font-size-2xs);
  margin-bottom: 5px;
}

.info {
  font-weight: var(--font-weight-bold);
  margin-bottom: 8px;
}
