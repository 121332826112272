.content {
  display: flex;
  margin-inline-start: var(--side-nav-width);
  padding: 2rem;
}

.title,
.subtitle {
  color: var(--color-text-tertiary);
  margin-bottom: 10px;
}

.column {
  flex: 1;
}

.search {
  margin-bottom: 20px;
}

.link {
  color: var(--color-text);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}

.recent {
  margin-top: 30px;
}
