.headerContent,
.employeeHeader {
  display: flex;
}

.employeeHeader {
  flex: 1;
}

.employeeLogo {
  margin-right: 20px;
}

.employeeInfo span {
  padding: 0 0 0 10px;
}

.employeeInfo,
.employeeActions {
  color: var(--color-text-tertiary);
}

.employeeStatus {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 10px;
}

.employeeActions > div {
  margin-bottom: 5px;
}

.employeeActions button {
  margin: 0 10px 0 0;
}

.link {
  color: var(--color-text-inverted) !important;
}

.bodyContent {
  margin-inline-start: var(--side-nav-width);
  padding: 2rem;
}

.footer {
  margin-top: auto;
}

.container table {
  width: 100%;
  color: var(--color-text-primary);
}

.container table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.container table tr:hover {
  background-color: var(--color-table-row--hover);
}

.container table tr:hover strong {
  text-decoration: underline;
}

.container table thead tr {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
}

.container table thead tr:hover {
  cursor: default;
  background: transparent;
}

.container table tr td button:first-child {
  position: relative;
  top: 1px;
}

.container table tr td button {
  margin: 0 0 0 8px;
}
