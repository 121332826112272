.content {
  margin-inline-start: var(--side-nav-width);
  flex: 1;
  padding: 2rem;
}

.action,
.filterText,
.filter {
  flex: 1;
}

.filter,
.filters,
.filterText {
  display: flex;
}

.filterText {
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 10px 10px 0;
}

.filter {
  flex-direction: column;
}

.filter:last-child {
  margin-left: 10px;
}

.table table {
  width: 100%;
  color: #3a3e47;
}

.table tr {
  height: 50px;
  border-bottom: 1px solid var(--color-border);
}

.table tr:hover {
  background-color: var(--color-table-row--hover);
  cursor: pointer;
}

.table tr:hover strong {
  text-decoration: underline;
}

.table tr.tableHeader {
  cursor: default;
  font-weight: var(--font-weight-bold);
}

.table tr.tableHeader:hover {
  background: transparent;
}

.table a:hover {
  text-decoration: none;
}
